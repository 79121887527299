<template>
  <div id="ship-track-result">
    <div class="result-header">
      <div class="status-image-container">
        <span :class="getStatusIconClass" />
      </div>
      <div class="result-header-info-container">
        <div class="result-tracking-number">
          Tracking Number: {{ trackingData.trackingnumber }}
        </div>
      </div>
      <div class="arrows-container">
        <div class="arrows-group">
          <span :class="arrow0Class" />
          <span :class="arrow1Class" style="margin-left: -3% !important" />
          <span :class="arrow2Class" style="margin-left: -3% !important" />
          <span :class="arrow3Class" style="margin-left: -3% !important" />
          <span :class="arrow4Class" style="margin-left: -3% !important" />
          <span :class="arrow5Class" style="margin-left: -3% !important" />
        </div>
      </div>
      <div class="expand-container" v-on:click="isExpanded = !isExpanded">
        <span class="show-details" v-if="!isExpanded" />
        <span class="hide-details" v-if="isExpanded" />
      </div>
    </div>
    <div v-if="isExpanded" class="result-content">
      <div class="shipment-overview-container">
        <div class="shipment-date-container" v-if="isDateValid">
          <div class="shipment-date-title-container">
            <span class="shipment-date-icon" /><span
              class="shipment-date-title"
              >{{ shipDateTitle }}</span
            >
          </div>
          <div
            :style="
              !trackingData.scheduledDELactive
                ? 'padding-bottom: 5px'
                : 'padding-bottom:0'
            "
          >
            <div class="shipment-date" v-html="shipDate" />
          </div>
          <div class="ship-date-button-container">
            <div
              v-if="showScheduledDelivery"
              class="scheduled-delivery-container"
            >
              <p class="scheduled-appointment-date">
                {{ scheduledDeliveryLabel }}
              </p>
              <p class="delivery-value">{{ scheduledDeliveryText }}</p>
            </div>
            <!-- SCHEDULED DELIVERY -->
            <div
              v-if="showScheduleDeliveryBlock"
              class="schedule-deliver-button-container"
            >
              <p class="detail-header-large">Schedule Delivery</p>
              <p>Click below to schedule your delivery appointment</p>
              <button
                class="schedule-delivery-button"
                @click="doScheduleDelivery"
              >
                <img src="../../assets/triangle-warning-80x80.png" />
                <span>Schedule Delivery</span>
              </button>
            </div>
            <!-- SCHEDULE PICKUP -->
            <div v-if="showSchedulePickup" class="schedule-pickup-container">
              <!--                <a href="https://www.jotform.com/EFWForms/return-shipment-with-efw" target="_blank">Click here to schedule your pickup</a>-->
              <p>Click below to schedule your pickup</p>
              <button
                class="schedule-delivery-button"
                @click="doSchedulePickup"
              >
                <img src="../../assets/triangle-warning-80x80.png" />
                <span>Schedule Pickup</span>
              </button>
            </div>
            <!-- TEXT OPT IN -->
            <div v-if="showOptInTextUpdates">
              <button
                class="show-text-updates-modal-button"
                @click="showModal('text-updates-modal')"
              >
                <div class="show-text-updates-modal-button-block">
                  <img src="../../assets/mobile-sms.svg" />
                  <span class="text-updates-button-padding">Text Updates</span>
                </div>
              </button>
              <TextUpdatesModal
                v-show="isTextUpdatesModalVisible"
                :trackingNum="trackingData"
                @close="closeModal('text-updates-modal')"
              />
            </div>
          </div>
        </div>

        <!-- SHIPMENT STATUS SECTION -->
        <div
          class="shipment-status-container"
          :class="{ 'shipment-status-container__invalid-date': !isDateValid }"
        >
          <div class="shipment-status-title-container">
            <span class="shipment-status-icon" />
            <span class="shipment-status-title"
              >Status:
              <span style="display: inline-block">{{
                shipmentStatus
              }}</span></span
            >
          </div>
          <div v-if="lastLocationAndDate !== ''" class="shipment-location">
            <p>Shipment passed through</p>
            <p>{{ lastLocationAndDate }}</p>
          </div>
          <!--          <div v-else class="shipment-location">-->
          <!--            <p>&nbsp;</p>-->
          <!--          </div>-->
          <!--=================Track Driver================-->
          <div v-if="showTrackYourDriver">
            <button
              class="track-driver-modal-button"
              @click="showModal('track-driver-modal')"
            >
              <div class="show-text-updates-modal-button-block">
                <img src="../../assets/track-driver.svg" />
                <span style="text-align: center">Track Your Driver!</span>
              </div>
            </button>
            <!--            <TrackDriverModal v-show="isTrackYourDriverModalVisible"-->
            <!--                              :sourceUrl="trackDriverUrl"-->
            <!--                              @close="closeModal('track-driver-modal')"/>-->
          </div>
          <!--============================================-->
          <div class="result-service-level-container">
            <span class="detail-header-large shipment-service-level"
              >Service Level: {{ trackingData.servicelevel }}</span
            >
          </div>
          <div
            v-if="showServiceLevelDetail"
            class="shipment-service-level-tooltip"
          >
            <p>{{ serviceLevelDescription }}</p>
          </div>
          <div>
            <div class="show-video-modal-block">
              <a
                v-if="isVideoAvailable"
                class="show-video-modal-link"
                @click="showModal('video-modal')"
              >
                <span
                  >Click here to watch a video about your service level.</span
                >
              </a>
            </div>
            <VideoModal
              v-show="isVideoModalVisible"
              :isVideoModalVisible="isVideoModalVisible"
              :serviceLevel="trackingData.servicelevel"
              :serviceCode="trackingData.servicelevelcode"
              :video="getVideo"
              @close="closeModal('video-modal')"
              @keyup.esc="closeModal('video-modal')"
            />
          </div>
          <div v-if="showPODDetails" class="pod-details-container">
            <span class="detail-header-standard">{{ podDetails }}</span>
          </div>

          <!-- productcode: HOME -->
          <div
            v-if="showProductCodeHomeDetails"
            class="prodcode-home-details-container"
          >
            Our standard Front Door Delivery service requires that we have clear
            access to a paved driveway that can support our delivery vehicle.
            Potential issues that could impede our ability to provide Front Door
            Delivery include: gravel driveways, steps or steep inclines leading
            to the home, and low-hanging branches or overhead wires. In some
            instances, your driver may need to place your shipment at the end of
            the driveway or another accessible location.
          </div>
        </div>
      </div>
      <!-- END SHIPMENT STATUS SECTION -->

      <hr class="gradient" />

      <!-- ITEM DETAILS -->
      <div class="shipment-details-container">
        <div
          v-if="isDomestic || isInternational"
          class="shipment-item-details-container"
        >
          <div class="shipment-item-details-title-container">
            <span class="shipment-item-details-icon" /><span
              class="shipment-item-details-title"
              >Item Details</span
            >
          </div>
          <div class="shipment-item-description-table-container">
            <table>
              <thead>
                <tr>
                  <th class="item-detail-table-header">Description</th>
                  <th v-if="isInternational" class="item-detail-table-header">
                    Type
                  </th>
                  <th class="item-detail-table-header">Pieces</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(freight, i) in trackingData.freight"
                  v-bind:key="descrItemKey(i)"
                >
                  <td class="item-detail">{{ freight.description }}</td>
                  <td v-if="isInternational" class="item-detail-center">
                    {{ freight.itemtype }}
                  </td>
                  <td class="item-detail-center">{{ freight.pieces }}</td>
                </tr>
              </tbody>
            </table>
            <ul class="shipment-items-summary">
              <!--<li v-if="this.trackingData.shipmenttype === 'international'"><b>Type:</b> {{ this.trackingData.transporttype }}</li>-->
              <li><b>Total Pieces:</b> {{ totalPieces }}</li>
              <li v-if="isDomestic">
                <b>Total Weight:</b> {{ totalWeight }} lbs
              </li>
              <li v-if="isInternational">
                <b>Total Weight:</b> {{ totalKilos }} kilos ({{ totalWeight }}
                lbs)
              </li>
            </ul>
            <ul v-if="showReferenceNum" class="shipment-items-summary">
              <li>
                Reference #: {{ this.trackingData.schedulingtrackingnumber }}
              </li>
            </ul>
          </div>
        </div>
        <!-- END ITEM DETAILS -->

        <!-- SHIPMENT PROGRESS -->
        <div
          v-bind:class="{
            'shipment-progress-container': isDomestic || isInternational,
            'shipment-item-details-container': isTruckload,
          }"
        >
          <div class="shipment-status-title-container">
            <span class="shipment-progress-icon" /><span
              class="shipment-status-title"
              >Shipment Progress</span
            >
          </div>
          <div class="shipment-progress-table-container">
            <table>
              <thead>
                <tr>
                  <th class="item-detail-table-header">Date</th>
                  <th class="item-detail-table-header">Time</th>
                  <th class="item-detail-table-header">Activity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(event, i) in events" v-bind:key="eventItemKey(i)">
                  <td
                    class="item-detail"
                    :class="{ 'item-detail-bold': event.code === 'XXX' }"
                  >
                    {{ formatDate(event.datetimeGMT) }}
                  </td>
                  <td
                    class="item-detail"
                    :class="{ 'item-detail-bold': event.code === 'XXX' }"
                    style="white-space: nowrap"
                  >
                    {{ formatTime(event.datetimeGMT) }}
                    {{ formatTimezone(event.datetimeGMT) }}
                  </td>
                  <td
                    class="item-detail"
                    :class="{ 'item-detail-bold': event.code === 'XXX' }"
                  >
                    {{ event.description }}
                    <span class="description-sub-text">
                      {{ eventDescriptionSubText(event) }}
                    </span>
                  </td>
                </tr>
                <tr v-if="showEstimatedDeliveryDisclaimer">
                  <td />
                  <td />
                  <td class="estimatedDeliveryDisclaimer">
                    <ul>
                      <li>
                        <span class="estimatedDeliveryDisclaimerText"
                          >Estimated Delivery Date is not an exact date and is
                          subject to change based on the appointment date
                          established with the customer.</span
                        >
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--            <div v-if="showChatNotification" class="chat-notification-container">-->
            <!--              <span class="material-icons shake">contact_support</span>-->
            <!--              <span class="chat-notification-text">Need additional help? Click on the chat below.</span>-->
            <!--            </div>-->
          </div>
        </div>
        <!-- END SHIPMENT PROGRESS -->
      </div>
    </div>
  </div>
</template>

<script>
import TextUpdatesModal from "@/components/shiptrack/ShipTrackTextUpdatesModal.vue";
// import TrackDriverModal from '@/components/shiptrack/ShipTrackDriverModal.vue'
import VideoModal from "@/components/shiptrack/ShipTrackVideoModal.vue";
// import VueAnalytics from "vue-analytics";

const PickupFlagDate = "07/07/08 00:00";
const PickupLevels = ["RN", "RT", "RI", "PD"];

export default {
  name: "ship-track-result",
  components: { TextUpdatesModal, VideoModal },
  props: {
    // modalComponent: null,
    trackingData: {
      type: Object,
      default: () => ({
        isOutForDelivery: "",
        shipmenttype: "domestic",
        trackingnumber: "XXXXXXXXXXXXX",
        schedulingtrackingnumber: "YYYYYYYYYYYY",
        servicelevel: "Service Level Descriptor",
        servicelevelcode: "TD",
        productcode: "NONE",
        readydateGMT: "11/08/15 18:36",
        events: [],
        freight: [],
        maxArrow: 0,
        serviceleveltext: "Service Level",
        scheduledDELactive: false,
        scheduledDelivery: {
          fromGMT: null,
          toGMT: null,
          scheduledDeliveryType: "By",
        },
      }),
    },
    daNumber: {
      default: "",
      type: String,
    },
    daWebsite: {
      default: "",
      type: String,
    },
    podName: {
      default: "",
      type: String,
    },
    podDate: {
      default: "",
      type: String,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    isMultiLoad: {
      type: Boolean,
      default: false,
    },
    emailAddress: {
      default: "updates@efwnow.com",
      type: String,
    },
  },
  data() {
    return {
      // trackDriverUrl: '',
      errors: [],
      email: null,
      trackingNum: Object,
      isVideoModalVisible: false,
      isTextUpdatesModalVisible: false,
      // isTrackYourDriverModalVisible: false,
      isVideoAvailable: true,
      isDateValid: true,
      toolTip: false,
      isExpanded: true,
      shipmentHasLocation: true,
      showChatNotification: true,
      videos: [
        {
          videoClass: "Basic",
          serviceCodes: ["TD", "FN"],
          src: "https://player.vimeo.com/video/352049937",
          url: "https://vimeo.com/352049937/877467eed7",
          id: "352049937",
        },
        {
          videoClass: "Standard",
          serviceCodes: ["FS", "RD"],
          src: "https://player.vimeo.com/video/352055856",
          url: "https://vimeo.com/352055856/36574dcb7f",
          id: "352055856",
        },
        {
          videoClass: "Through the Door",
          serviceCodes: ["ID", "L1", "L2", "LS", "TH"],
          src: "https://player.vimeo.com/video/352052236",
          url: "https://vimeo.com/352052236/65866d2b06",
          id: "352052236",
        },
        {
          videoClass: "Room of Choice",
          serviceCodes: ["RC", "S1", "S2", "SV"],
          src: "https://player.vimeo.com/video/352053794",
          url: "https://vimeo.com/352053794/dcce1e6ca6",
          id: "352053794",
        },
        {
          videoClass: "White Glove",
          serviceCodes: ["CT", "P1", "P2", "PL", "WA", "WD", "WG"],
          src: "https://player.vimeo.com/video/352053057",
          url: "https://vimeo.com/352053057/8cc84da932",
          id: "352053057",
        },
      ],
    };
  },
  created: function () {
    // `this` points to the vm instance
  },
  methods: {
    // toggleExpand: function () {
    //   this.isExpanded = !this.isExpanded
    // },
    showModal(modal) {
      // console.log('VAR: ' + process.env.VUE_APP_ROOT_API)
      if (modal === "text-updates-modal") this.isTextUpdatesModalVisible = true;
      if (modal === "track-driver-modal") {
        let trackDriverUrl =
          "https://" +
          this.daWebsite +
          "/track_order/estes360?order_number=" +
          this.daNumber +
          "&find=";
        window.open(trackDriverUrl, "_blank");
        // this.isTrackYourDriverModalVisible = true
      }
      if (modal === "video-modal") {
        this.isVideoModalVisible = true;
        this.sendGA();
      }
    },
    closeModal(modal) {
      if (modal === "text-updates-modal")
        this.isTextUpdatesModalVisible = false;
      // if (modal === 'track-driver-modal') this.isTrackYourDriverModalVisible = false
      if (modal === "video-modal") this.isVideoModalVisible = false;
    },
    sendGA() {
      //TODO: vue3 upgrade ensure that updated g-tag works? And remove commented out $ga event below once confirmed
      this.$gtag.event("click", {
        event_category: "video",
        event_label:
          this.trackingData.servicelevelcode +
          "_" +
          this.trackingData.servicelevel,
        value: this.getVideo.id,
      });
      // this.$ga.event({
      //   eventCategory: "video",
      //   eventAction: "click",
      //   eventLabel:
      //     this.trackingData.servicelevelcode +
      //     "_" +
      //     this.trackingData.servicelevel,
      //   eventValue: this.getVideo.id,
      // });
    },
    showVideoBlock(bool) {
      // bool === true ? this.isVideoAvailable = true : this.isVideoAvailable = false
      this.isVideoAvailable = bool;
    },
    isIE: function isIE() {
      const ua = navigator.userAgent;
      // MSIE used to detect old browsers and Trident used to newer ones*/
      return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    },
    doScheduleDelivery: function () {
      // let scheduleDeliveryURL = 'https://www.efwnow.com/WebTrakWT/InterDel/DelAppt.aspx?CustLogin=Y&TrackingNo=' + this.trackingData.schedulingtrackingnumber
      let scheduleDeliveryURL = "https://efwtrack.com/appointments/";
      window.open(scheduleDeliveryURL, "_blank");
    },
    doSchedulePickup() {
      // let schedulePickupURL = 'https://www.jotform.com/EFWForms/return-shipment-with-efw'
      let schedulePickupURL = "https://efwtrack.com/pickup-scheduling";
      window.open(schedulePickupURL, "_blank");
    },
    formatDate: function (dateStr) {
      let date = new Date(dateStr + "" + " GMT")
        .toLocaleString()
        .replace(/[^A-Za-z 0-9 .,?""!@#$%^&*()-_=+;:<>/\\|}{[\]`~]*/g, "");
      let dateStrParts = date.split(" ");
      let datePart = dateStrParts[0].replace(",", "");
      let dateParts = datePart.split("/");
      if (parseInt(dateParts[2]) < 2000) {
        let newYear = parseInt(dateParts[2]) + 100;
        datePart = dateParts[0] + "/" + dateParts[1] + "/" + newYear.toString();
      }
      return datePart;
    },
    formatTime: function (dateStr) {
      let date = new Date(dateStr + "" + " GMT")
        .toLocaleString()
        .replace(/[^A-Za-z 0-9 .,?""!@#$%^&*()-_=+;:<>/\\|}{[\]`~]*/g, "");
      let dateStrParts = date.split(" ");
      let timeParts = dateStrParts[1].split(":");
      return timeParts[0] + ":" + timeParts[1] + " " + dateStrParts[2];
    },
    formatTimezone: function (dateStr) {
      let date = new Date(dateStr + "" + " GMT");
      return date
        .toLocaleDateString(undefined, {
          day: "2-digit",
          timeZoneName: "short",
        })
        .substring(4);
    },
    createDate(dateStr) {
      return new Date(dateStr + "" + " GMT")
        .toLocaleString()
        .replace(/[^A-Za-z 0-9 .,?""!@#$%^&*()-_=+;:<>/\\|}{[\]`~]*/g, "");
    },
    descrItemKey: function (index) {
      return "descr_item_" + index;
    },
    eventItemKey: function (index) {
      return "event_" + index;
    },
    eventsContainStatuses: function (checkStatuses) {
      for (let i = 0; i < this.trackingData.events.length; i++) {
        if (checkStatuses.indexOf(this.trackingData.events[i].code) > -1) {
          return true;
        }
      }
      return false;
    },
    eventDescriptionSubText(event) {
      if (event.code === "DEL") {
        if (this.trackingData.PODName) {
          return `Signed by ${this.trackingData.PODName}`;
        }
      }

      return "";
    },
  },
  computed: {
    shipDateTitle() {
      // if (PickupLevels.includes(this.trackingData.servicelevelcode)) {
      if (PickupLevels.indexOf(this.trackingData.servicelevelcode) !== -1) {
        return "Scheduled Pick Up Date:";
      }

      return "Ship Date:";
    },
    // shipDate: function () {
    //   return this.formatDate(this.trackingData.readydateGMT)
    // },
    shipDate: function () {
      // if (PickupLevels.includes(this.trackingData.servicelevelcode)) {
      if (PickupLevels.indexOf(this.trackingData.servicelevelcode) !== -1) {
        if (this.trackingData.shipmentdate === PickupFlagDate) {
          return '<span class="shipment-date-pending" style="font-weight: bold; font-size: 18px;">Pending</span>';
        }

        return this.formatDate(this.trackingData.shipmentdate);
      }

      return this.formatDate(this.trackingData.readydateGMT);
    },
    events() {
      let sortedEvents = [];
      let estimateDelDate = null;
      for (let i = 0; i < this.trackingData.events.length; i++) {
        let event = this.trackingData.events[i];
        if (event.code === "ETD") {
          estimateDelDate = event;
        } else {
          if (event.code !== "REC" && event.code !== "APP") {
            let index = -1;
            for (let i = 0; i < sortedEvents.length; i++) {
              if (event.description === sortedEvents[i].description) {
                index = i;
                break;
              }
            }

            if (index === -1) {
              // manual change descriptions
              if (event.code === "CXL") {
                event.description = "Cancelled";
              }

              // not found add it to the array
              sortedEvents.push(event);
            } else {
              // duplicate found determine which to keep
              let foundEvent = sortedEvents[index];
              let foundEventDate = this.createDate(foundEvent.datetimeGMT);
              let currentEventDate = this.createDate(event.datetimeGMT);

              if (currentEventDate < foundEventDate) {
                sortedEvents[index] = event;
              }
            }
          }
        }
      }

      // reverse array of shipment updates
      sortedEvents = sortedEvents.reverse();

      // if there is an estimated delivery date, append it
      if (estimateDelDate) {
        sortedEvents.push(estimateDelDate);
      }

      return sortedEvents;
    },
    showOptInTextUpdates() {
      const excludeLevels = ["DT", "PD", "DR", "RT", "RN", "RI"];
      if (this.trackingData.productcode) {
        let productCode = this.trackingData.productcode.toUpperCase();

        if (productCode === "AMAT" || productCode === "DOM") {
          return false;
        }

        if (productCode === "HOME") {
          // return !excludeLevels.includes(this.trackingData.servicelevelcode)
          for (let i in excludeLevels) {
            console.log(
              `lvl: ${excludeLevels[i]} - slc: ${this.trackingData.servicelevelcode}`
            );
            if (excludeLevels[i] === this.trackingData.servicelevelcode) {
              return false;
            }
          }

          return true;
        }
      }
      return false;
    },
    showTrackYourDriver() {
      let filteredEvents = [];
      for (let i = 0; i < this.trackingData.events.length; i++) {
        if (this.trackingData.events[i].code !== "APP") {
          filteredEvents.push(this.trackingData.events[i]);
        }
      }

      if (filteredEvents.length > 0) {
        let lastEvent = filteredEvents[filteredEvents.length - 1];
        if (lastEvent) {
          if (
            lastEvent.code === "OFD" &&
            this.daNumber !== "" &&
            this.daWebsite !== ""
          ) {
            console.log(
              "IT IS OFD!!!" +
                " daNumber: " +
                this.daNumber +
                " website: " +
                this.daWebsite
            );
            return true;
          }
        }
      }

      return false;
    },
    showEstimatedDeliveryDisclaimer() {
      if (this.trackingData.events) {
        let events = this.trackingData.events;
        for (let i = 0; i < events.length; i++) {
          if (events[i].code && events[i].code === "ETD") {
            return true;
          }
        }
      }
      return false;
    },
    isDomestic() {
      return this.trackingData.shipmenttype === "domestic";
    },
    isInternational() {
      return this.trackingData.shipmenttype === "international";
    },
    isTruckload() {
      return this.trackingData.shipmenttype === "truckload";
    },
    getStatusIconClass: function () {
      if (this.trackingData.events.length > 0) {
        if (this.trackingData.scheduledDELactive) {
          return "status-scheduledelivery";
        }

        let lastEvent =
          this.trackingData.events[this.trackingData.events.length - 1];

        if (
          this.trackingData.scheduledDelivery !== "" &&
          this.trackingData.scheduledDelivery !== null
        ) {
          if (lastEvent.code !== "OFD" && lastEvent.code !== "DEL") {
            return "status-packagescheduled";
          }
        }

        switch (lastEvent.code) {
          case "PUP":
            return "status-intransit";

          case "OHO":
            return "status-intransit";

          case "INT":
            return "status-intransit";

          case "OHD":
          case "REC":
            return "status-intransit";

          case "OFD":
            return "status-outfordelivery";

          case "DEL":
            return "status-delivered";
        }
      }

      return "status-intransit";
    },
    scheduledDeliveryText: function () {
      let fromDate = this.formatDate(
        this.trackingData.scheduledDelivery.fromGMT
      );
      let fromTime = this.formatTime(
        this.trackingData.scheduledDelivery.fromGMT
      );
      let fromTimezone = this.formatTimezone(
        this.trackingData.scheduledDelivery.fromGMT
      );
      let toDate = this.formatDate(this.trackingData.scheduledDelivery.toGMT);
      let toTime = this.formatTime(this.trackingData.scheduledDelivery.toGMT);
      let toTimezone = this.formatTimezone(
        this.trackingData.scheduledDelivery.toGMT
      );

      if (
        this.trackingData.scheduledDelivery.scheduledDeliveryType === "By" ||
        this.trackingData.scheduledDelivery.scheduledDeliveryType == null
      ) {
        return `by ${fromTime} on ${fromDate} ${fromTimezone}`;
      }

      if (
        this.trackingData.scheduledDelivery.scheduledDeliveryType === "Between"
      ) {
        if (fromDate === toDate) {
          return `${fromDate} between ${fromTime} ${fromTimezone} and ${toTime}`;
        } else {
          return `${fromDate} at ${fromTime} ${fromTimezone} and ${toDate} at ${toTime} ${toTimezone}`;
        }
      }

      if (
        this.trackingData.scheduledDelivery.scheduledDeliveryType === "Only"
      ) {
        return `at ${fromTime} ${fromTimezone} on ${fromDate}`;
      }

      return "";
    },
    scheduledDeliveryLabel: function () {
      if (this.trackingData.productcode) {
        let productCode = this.trackingData.productcode.toUpperCase();
        if (productCode === "AMAT" || productCode === "DOM") {
          return "Estimated Delivery Date:";
        }
      }
      return "Scheduled Appointment Delivery Date:";
    },
    showScheduledDelivery: function () {
      // if (this.trackingData.shipmenttype === 'international' || this.trackingData.shipmenttype === 'truckload') {
      //   return false
      // }

      if (this.trackingData.shipmenttype === "international") {
        return false;
      }

      if (PickupLevels.indexOf(this.trackingData.servicelevelcode) !== -1) {
        return false;
      }

      if (
        !this.trackingData.scheduledDELactive &&
        this.trackingData.scheduledDelivery.fromGMT !== null &&
        this.trackingData.scheduledDelivery.fromGMT !== "" &&
        this.trackingData.scheduledDelivery.toGMT !== null &&
        this.trackingData.scheduledDelivery.toGMT !== ""
      ) {
        return !this.eventsContainStatuses(["DEL", "CLS"]);
      }

      console.log("here 3");
      return false;
    },
    showScheduleDeliveryBlock: function () {
      if (this.emailAddress.toLowerCase() !== "efwhorizon@efwnow.com") {
        return this.trackingData.scheduledDELactive;
      }
      return false;
    },
    showSchedulePickup() {
      return this.trackingData.shipmentdate === PickupFlagDate;
    },
    shipmentStatus: function () {
      // if (PickupLevels.includes(this.trackingData.servicelevelcode)) {
      if (PickupLevels.indexOf(this.trackingData.servicelevelcode) !== -1) {
        if (this.trackingData.shipmentdate === PickupFlagDate) {
          return "Pickup Appointment Pending";
        }
      }

      // filter out 'Appointment Created' *can't use JS shorthand array methods for IE support
      let filteredEvents = [];
      for (let i = 0; i < this.trackingData.events.length; i++) {
        if (this.trackingData.events[i].code !== "APP") {
          filteredEvents.push(this.trackingData.events[i]);
        }
      }
      if (filteredEvents.length > 0) {
        let event = filteredEvents[filteredEvents.length - 1];
        return event.description;
      }
      // if (this.trackingData.events.length > 0) {
      //   let event = this.trackingData.events[this.trackingData.events.length - 1]
      //   return event.description
      // }
      return "Not Available";
    },
    lastLocationAndDate: function () {
      if (this.trackingData.freightlocations.length > 0) {
        let location =
          this.trackingData.freightlocations[
            this.trackingData.freightlocations.length - 1
          ];

        return (
          location.city +
          ", " +
          location.state +
          " on " +
          this.formatDate(location.datetimeGMT)
        );
      }
      return "";
    },
    totalPieces: function () {
      let count = 0;

      for (let i = 0; i < this.trackingData.freight.length; i++) {
        let freight = this.trackingData.freight[i];
        count += parseInt(freight.pieces);
      }
      return count;
    },
    totalWeight: function () {
      let weight = 0;

      for (let i = 0; i < this.trackingData.freight.length; i++) {
        let freight = this.trackingData.freight[i];
        weight += parseFloat(freight.weight);
      }
      return weight;
    },
    totalKilos: function () {
      let kilos = 0;

      for (let i = 0; i < this.trackingData.freight.length; i++) {
        let freight = this.trackingData.freight[i];
        if (freight.kilos !== undefined) {
          kilos += parseFloat(freight.kilos);
        }
      }
      return kilos;
    },
    showReferenceNum: function () {
      return (
        this.trackingData.trackingnumber !==
        this.trackingData.schedulingtrackingnumber
      );
    },
    showServiceLevelDetail: function () {
      return (
        this.trackingData.servicelevel !== this.trackingData.serviceleveltext
      );
    },
    serviceLevelDescription: function () {
      if (this.trackingData.productcode) {
        let productCode = this.trackingData.productcode.toUpperCase();
        if (
          productCode === "AMAT" &&
          this.trackingData.servicelevelcode === "EC"
        ) {
          return "Delivery by 5:00 PM on or before the fifth business day.";
        }
      }
      return this.trackingData.serviceleveltext;
    },
    showPODDetails: function () {
      return this.podName !== "" && this.podDate !== "";
    },
    podDetails: function () {
      let dateTime = `${this.formatDate(this.podDate)} ${this.formatTime(
        this.podDate
      )}`;
      return `Signed for by ${this.podName} on ${dateTime}`;
    },
    showProductCodeHomeDetails: function () {
      return this.trackingData.productcode === "HOME";
    },
    arrow0Class: function () {
      if (this.trackingData.maxArrow > 0) {
        return "blue-arrow-head";
      }
      // if (this.eventsContainStatuses(['PUP', 'OHO', 'INT', 'OHD', 'REC', 'OFD', 'DEL', 'CLS'])) {
      //   return 'blue-arrow-head'
      // }
      return "gray-arrow-head";
    },
    arrow1Class: function () {
      if (this.trackingData.maxArrow > 1) {
        return "blue-arrow-tail";
      }
      // if (this.eventsContainStatuses(['OHO', 'INT', 'OHD', 'REC', 'OFD', 'DEL', 'CLS'])) {
      //   return 'blue-arrow-tail'
      // }
      return "gray-arrow-tail";
    },
    arrow2Class: function () {
      if (this.trackingData.maxArrow > 2) {
        return "blue-arrow-tail";
      }
      // if (this.eventsContainStatuses(['INT', 'OHD', 'REC', 'OFD', 'DEL', 'CLS'])) {
      //   return 'blue-arrow-tail'
      // }
      return "gray-arrow-tail";
    },
    arrow3Class: function () {
      if (this.trackingData.maxArrow > 3) {
        return "blue-arrow-tail";
      }
      // if (this.eventsContainStatuses(['OHD', 'REC', 'OFD', 'DEL', 'CLS'])) {
      //   return 'blue-arrow-tail'
      // }
      return "gray-arrow-tail";
    },
    arrow4Class: function () {
      if (this.trackingData.maxArrow > 4) {
        return "blue-arrow-tail";
      }
      // if (this.eventsContainStatuses(['OFD', 'DEL', 'CLS'])) {
      //   return 'blue-arrow-tail'
      // }
      return "gray-arrow-tail";
    },
    arrow5Class: function () {
      if (this.trackingData.maxArrow > 5) {
        return "blue-arrow-tail";
      }
      // if (this.eventsContainStatuses(['DEL', 'CLS'])) {
      //   return 'blue-arrow-tail'
      // }
      return "gray-arrow-tail";
    },
    getVideo() {
      // this.videos.find(video => video.serviceCodes.find(code => {
      //   if (code === this.trackingData.servicelevelcode) return video.id
      // }))
      for (let i = 0; i < this.videos.length; i++) {
        if (
          this.videos[i].serviceCodes.indexOf(
            this.trackingData.servicelevelcode
          ) !== -1
        ) {
          this.showVideoBlock(true);
          return this.videos[i];
        } else {
          this.showVideoBlock(false);
        }
      }
      return this.videos[0];
    },
  },
  mounted: function () {
    if (this.trackingData.scheduledDELactive) {
      this.isExpanded = true;
    } else {
      this.isExpanded = this.expanded;
    }

    // if (this.trackingData.shipdateGMT.length <= 0) this.isDateValid = false

    console.log(`shipDatexxxx: ${this.shipDate}`);
    if (this.shipDate === "Invalid") this.isDateValid = false;

    // icPatronChat.init({serverHost:'https://home-c32.nice-incontact.com',bus_no:4597861,poc:'fc6ae7cb-1e5f-4fa0-881c-8cc0f761e0c2',params:['FirstName','Last Name','first.last@company.com',555-555-5555]})
  },
};
</script>

<!-- styling for the component -->
<style scoped>
.text-updates-button-padding {
  padding-left: 4px;
}

.chat-notification-container {
  display: flex;
  align-items: center;
  padding-top: 16px;
}

.chat-notification-text {
  padding-left: 5px;
}

/*.shake {*/
/*  !*animation: shake-animation 0.82s cubic-bezier(.36,.07,.19,.97) both;*!*/
/*  !*animation: shake-animation 60s infinite;*!*/
/*  animation: shake-animation 10s infinite;*/
/*  !*animation-delay: 60s;*!*/
/*  transform: translate3d(0, 0, 0);*/
/*  !*animation-iteration-count: infinite;*!*/
/*}*/
/*@keyframes shake-animation {*/
/*  0% {*/
/*    transform: translate3d(0, 0, 0);*/
/*  }*/
/*  97.55%, 98.75% {*/
/*    transform: translate3d(-1px, 0, 0);*/
/*  }*/
/*  97.7%, 98.6% {*/
/*    transform: translate3d(2px, 0, 0);*/
/*  }*/
/*  97.85%, 98.15%, 98.45% {*/
/*    transform: translate3d(-4px, 0, 0);*/
/*  }*/
/*  98%, 98.3% {*/
/*    transform: translate3d(4px, 0, 0);*/
/*  }*/

/*  !*10%, 90% {*!*/
/*  !*  transform: translate3d(-1px, 0, 0);*!*/
/*  !*}*!*/
/*  !*20%, 80% {*!*/
/*  !*  transform: translate3d(2px, 0, 0);*!*/
/*  !*}*!*/
/*  !*30%, 50%, 70% {*!*/
/*  !*  transform: translate3d(-4px, 0, 0);*!*/
/*  !*}*!*/
/*  !*40%, 60% {*!*/
/*  !*  transform: translate3d(4px, 0, 0);*!*/
/*  !*}*!*/
/*}*/

table tr {
  line-height: 16px;
}

ol,
ul {
  list-style: none;
}

hr.gradient {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  border: 0;
  height: 1px;
}

#ship-track-result {
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16),
    0 -2px 10px 0 rgba(0, 0, 0, 0.12);
  display: inline-block;
  align-items: center;
  margin-bottom: 40px !important;
  width: 100%;
}
.result-header {
  align-items: center;
  background-color: #002d5b;
  color: #ffffff;
  display: flex;
  font-weight: bold;
  padding: 10px;
  text-align: left;
  visibility: visible;
}
.result-tracking-number {
  font-size: 24px;
}
.result-service-level {
  font-size: 18px;
}

.status-delivered {
  background: url("../../assets/status-delivered.svg") center no-repeat;
}
.status-intransit {
  background: url("../../assets/status-intransit.svg") center no-repeat;
}
.status-outfordelivery {
  background: url("../../assets/status-outfordelivery.svg") center no-repeat;
}
.status-packagescheduled {
  background: url("../../assets/status-packagescheduled.svg") center no-repeat;
}
.status-scheduledelivery {
  background: url("../../assets/status-scheduledelivery.svg") center no-repeat;
}
.status-delivered,
.status-intransit,
.status-outfordelivery,
.status-packagescheduled,
.status-scheduledelivery {
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.result-service-level-question-mark {
  margin-left: 5px !important;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("../../assets/question_mark.svg") center no-repeat;
  width: 15px;
  height: 15px;
}

.show-details,
.hide-details {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 100%; /* Width of new image */
}
.show-details {
  background: url("../../assets/plus-button.svg") center no-repeat;
}
.hide-details {
  background: url("../../assets/minus-symbol.svg") center no-repeat;
}

.result-content {
  color: black;
  margin-bottom: 20px !important;
  padding: 10px;
}
.shipment-date {
  color: #000;
}
.shipment-status {
  font-weight: 300;
}
.shipment-status,
.shipment-location {
  color: #808285;
}

.shipment-date,
.shipment-location {
  font-size: 16px;
  font-weight: 400;
  padding-left: 36px;
}
.detail-header-large {
  color: #002d5b;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}
.detail-header-standard {
  color: #002d5b;
  font-size: 18px;
}

/*-------------------
    Icon Styles
  -------------------*/
.shipment-date-icon,
.shipment-status-icon,
.shipment-item-details-icon,
.shipment-progress-icon {
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 24px; /* Width of new image */
  height: 24px;
}
.shipment-date-icon {
  background: url("../../assets/calendar.svg") center no-repeat;
}
.shipment-status-icon {
  background: url("../../assets/delivery-truck.svg") center no-repeat;
}
.shipment-item-details-icon {
  background: url("../../assets/box.svg") center no-repeat;
}
.shipment-progress-icon {
  background: url("../../assets/line-chart.svg") center no-repeat;
}

/*-------------------
    Title Styles
  -------------------*/
.shipment-date-title,
.shipment-status-title,
.shipment-item-details-title {
  color: #002d5b;
  font-size: 24px;
  font-weight: 500;
  padding-left: 12px;
}

.shipment-date-pending {
  font-weight: bold !important;
}

.scheduled-appointment-date {
  color: #002d5b;
  font-size: 22px;
  font-weight: bold;
}

/*-------------------
    Container Styles
  -------------------*/
.expand-container,
.shipment-date-title-container,
.shipment-item-details-title-container,
.result-service-level-container,
.shipment-status-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.status-image-container {
  align-items: center;
  display: flex;
  /*justify-content: center;*/
  height: 48px;
  margin: 0 5px 0 15px !important;
  width: 48px; /* Width of new image */
}
.result-header-info-container {
  align-self: center;
  display: flex;
  padding: 0 0 0 10px;
  width: 40%;
}
.arrows-container {
  display: inline-flex;
  align-items: center;
  margin-right: 20px !important;
  text-align: right;
  width: 60%;
}
.expand-container {
  justify-content: center;
  height: 60px;
  padding-right: 10px;
  width: 60px;
}
.scheduled-delivery-container,
.schedule-deliver-button-container {
  padding-top: 12px;
  padding-left: 36px;
}
.shipment-overview-container,
.shipment-details-container {
  display: flex;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.shipment-date-container,
.shipment-item-details-container {
  margin: 5px 0 0 10px !important;
  text-align: left;
  width: 40%;
}
.shipment-status-container,
.shipment-progress-container {
  margin: 5px 0 0 50px !important;
  text-align: left;
  width: 60%;
}
.shipment-status-container__invalid-date {
  margin-left: 10px !important;
}

.schedule-pickup-container {
  padding-top: 16px;
  padding-left: 36px;
  padding-bottom: 16px;
}

.result-service-level-container {
  padding-top: 12px;
}

.shipment-service-level-tooltip {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin: 0 24px 0 0 !important;
  width: 60%;
}
.shipment-service-level,
.shipment-service-level-tooltip,
.shipment-progress-table-container,
.shipment-item-description-table-container {
  padding-left: 36px;
}
.shipment-items-summary {
  padding: 0 0 0 0.25rem;
  list-style-type: none;
  margin: 40px auto !important;
}
.item-detail,
.item-detail-center,
.item-detail-table-header {
  font-size: 15px;
  font-weight: 400;
  padding-right: 10px;
  padding-bottom: 10px;
  text-align: left;
}
.item-detail {
  padding-right: 20px;
}
.item-detail-bold {
  font-weight: bold !important;
}
.item-detail-center {
  text-align: center;
}
.item-detail-table-header {
  color: #002d5b;
}

.pod-details-container {
  padding-left: 36px;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 0 0 !important;
  width: 80%;
}

.prodcode-home-details-container {
  padding-left: 36px;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 0 0 !important;
  width: 80%;
}

/*-------------------
    Arrow Styles
  -------------------*/
.blue-arrow-head {
  /*background: url('../../assets/head_arrow_blue.svg') center no-repeat;*/
  background: url("../../assets/fullbasearrow.svg") center no-repeat;
}
.gray-arrow-head {
  /*background: url('../../assets/head_arrow_gray.svg') center no-repeat;*/
  background: url("../../assets/outlinedbasearrow.svg") center no-repeat;
}
.blue-arrow-tail {
  /*background: url('../../assets/tail_arrow_blue.svg') center no-repeat;*/
  background: url("../../assets/fullforwardarrow.svg") center no-repeat;
}
.gray-arrow-tail {
  /*background: url('../../assets/tail_arrow_gray.svg') center no-repeat;*/
  background: url("../../assets/outlinedforwardarrow.svg") center no-repeat;
}
.blue-arrow-head,
.gray-arrow-head,
.blue-arrow-tail,
.gray-arrow-tail {
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 16.6%; /* Width of new image */
  height: 100%;
  /*height: 236px; !* Height of new image *!*/
}
.arrows-group {
  height: 60px;
  padding: 10px 0;
  width: 100%;
  max-width: 700px;
}

/*-------------------
    Button Styles
  -------------------*/
.schedule-delivery-button,
.show-text-updates-modal-button {
  background-color: #ffffff;
  border: 2px solid #002d5b;
  border-radius: 6px;
  cursor: pointer;
  color: #002d5b;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  text-align: left;
  text-decoration: none;
}
.schedule-delivery-button {
  margin: 10px 5px 10px 0 !important;
  padding: 10px 20px;
  /*vertical-align: middle;*/
  width: 215px;
}
.schedule-delivery-button img {
  height: 25px;
  width: 25px;
  /*position: absolute;*/
}
.schedule-delivery-button img,
.show-text-updates-modal-button img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px !important;
}
.schedule-delivery-button span,
.show-text-updates-modal-button span {
  display: inline-block;
  vertical-align: middle;
  color: #002d5b;
  font-size: 16px;
  font-weight: bold;
}
.schedule-delivery-button:focus,
.show-text-updates-modal-button:focus {
  outline: 0;
}
.show-text-updates-modal-button {
  align-items: flex-start;
  margin: 5px 5px 5px 36px !important;
  padding: 10px 0 10px 22px;
  vertical-align: middle;
  /*width: 215px;*/
  width: 185px;
}
.show-text-updates-modal-button img {
  align-items: flex-start;
  height: 22px;
  width: 22px;
}
.track-driver-modal-button {
  background-color: #ffffff;
  border: 2px solid #002d5b;
  border-radius: 6px;
  cursor: pointer;
  color: #002d5b;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  text-align: left;
  text-decoration: none;
}
.track-driver-modal-button img {
  display: inline-block;
  vertical-align: middle;
}
.track-driver-modal-button span {
  display: inline-block;
  vertical-align: middle;
  color: #002d5b;
  font-size: 16px;
  font-weight: bold;
}
.track-driver-modal-button:focus {
  outline: 0;
}
.track-driver-modal-button {
  align-items: flex-start;
  margin: 8px 5px 5px 36px !important;
  padding: 2px 0 4px 16px;
  vertical-align: middle;
  width: 230px;
}
.track-driver-modal-button img {
  align-items: flex-start;
  height: 42px;
  width: 42px;
}

/* Video Modal */
.show-video-modal-block {
  margin: 5px 0 15px 0 !important;
}
.show-video-modal-link {
  color: #fc4d02;
  cursor: pointer;
  display: inline-block;
  /*margin: 5px 0 !important;*/
  padding-left: 36px;
  text-decoration: none;
}
.show-video-modal-link:hover {
  color: #002855;
}

/* Esimated Delivery */
.estimatedDeliveryDisclaimer {
  padding-right: 125px;
}

.estimatedDeliveryDisclaimer ul {
  /*margin: 0;*/
  padding: 0;
  margin-left: 0;
  list-style: none;
  text-indent: -12px;
}

.estimatedDeliveryDisclaimer li {
  position: relative;
  list-style: none;
  padding-left: 0px;
}

.estimatedDeliveryDisclaimer li:before {
  content: "*";
  position: relative;
  /*left: -15px;*/
}

.estimatedDeliveryDisclaimer span {
  padding-left: 5px;
}

.description-sub-text {
  color: grey;
  font-size: 14px;
  padding-left: 8px;
}

/*.estimatedDeliveryDisclaimer:before {*/
/*  content: "*";*/
/*  position: relative;*/
/*}*/

/*-------------------
    Media Queries
  -------------------*/
@media (max-width: 910px) {
  /*Break point for the accordion header and the bottom boxes in the accordion*/
  .arrows-container {
    display: none;
  }
  .expand-container {
    width: 50px;
    margin-left: auto !important;
  }
  .result-header-info-container {
    width: 75%;
  }
  .shipment-details-container,
  .shipment-overview-container {
    /*flex-wrap: wrap;*/
    flex-direction: column;
  }
  .shipment-service-level-tooltip {
    width: 80%;
  }
  .shipment-date-container,
  .shipment-item-details-container {
    width: 100%;
  }
  .shipment-status-container {
    width: 100%;
    margin: 20px 0 0 5px !important;
  }
  .shipment-status-container .shipment-status-title {
    padding-left: 3px;
  }
  .shipment-status-icon {
    width: 36px;
  }
  .shipment-progress-container {
    width: 100%;
    margin: 5px 0 0 10px !important;
  }
  .estimatedDeliveryDisclaimer {
    padding-right: 25px;
  }
}

@media (max-width: 550px) {
  .status-image-container {
    display: none;
  }
  .result-header-info-container {
    width: 60%;
  }
}

/*iPhone fixes*/
@media (max-width: 420px) {
  .shipment-progress-container,
  .shipment-item-details-container,
  .shipment-date-container {
    margin-left: 0 !important;
  }
  .shipment-status-container {
    margin-left: -5px !important;
  }
  .shipment-progress-table-container,
  .shipment-item-description-table-container,
  .scheduled-delivery-container {
    padding: 5px 0 0 0;
  }
  .show-video-modal-link,
  .shipment-location,
  .shipment-service-level-tooltip,
  .shipment-service-level {
    padding: 5px 0 0 5px;
  }
  .shipment-date {
    padding: 7px 0 0 0;
  }
  .show-text-updates-modal-button {
    margin: 5px 0 !important;
  }
  .track-driver-modal-button {
    margin: 5px 0 !important;
  }
}
</style>
